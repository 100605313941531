<template>
  <v-container fluid>
    <v-card class="border-top-blue">
      <v-card-text>
        <questions-list></questions-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import QuestionsList from '../components/QuestionsList';

export default {
  name: 'questions-page',
  components: {
    QuestionsList
  }
};
</script>
